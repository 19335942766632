// ==============================================

//        MIXINS

// ==============================================



//  Colors
// -----------------------------------------------

// create colors CSS variables from Sass variables (formats hex, hsl, h, s, l)
// --color-primary: #ff0000;
// --color-primary-hsl: 0, 100%, 50%;   -> used in alpha() function
// --color-primary-h: 0;
// --color-primary-s: 0;   -> used in saturation() function
// --color-primary-l: 0;   -> used in lightness() function
@mixin defineCssVarsColors($color-name, $value) {
  $hue            : hue($value);
  $saturation     : saturation($value);
  $lightness      : lightness($value);
  --#{$color-name}    : #{$value};     // hex notation
  --#{$color-name}-hsl: #{$hue}, #{$saturation}, #{$lightness};
  --#{$color-name}-h  : #{$hue};
  --#{$color-name}-s  : #{$saturation};
  --#{$color-name}-l  : #{$lightness};
  // --#{$color-name}-hsl2: #{unquote("hsl(#{$hue}, #{$saturation}, #{$lightness})")};
  
  $red            : red($value);
  $green          : green($value);
  $blue           : blue($value);
  --#{$color-name}-rgb : #{$red}, #{$green}, #{$blue};

  // rgb notation unused
  // --#{$color-name}-r   : $red;
  // --#{$color-name}-g   : $green;
  // --#{$color-name}-b   : $blue;
  // --#{$color-name}-rgb2: #{unquote("rgb(#{$red}, #{$green}, #{$blue})")};
  // @debug "|| #{$color-name}, #{$value}";
}



//  Visual debugger
// -----------------------------------------------



// Debug with background
@mixin bg($color:#ff0000, $opacity:0.1) {
  background-color: rgba($color, $opacity);
}

// Debug with border
@mixin bd($color:#000000, $opacity:0.15, $thickness:1, $offset:null) {
  outline: #{$thickness}px dotted rgba($color, $opacity);
  @if $offset { outline-offset: #{$offset}px };
}

$color-breakpoints: (
  xs : #ffe082,
  sm : #c5e1a5,
  md : #80deea,
  lg : #9fa8da,
  xl : #f48fb1,
  xxl: #ffab91
) !default;

// Debug with background
@mixin bg-breakpoint($bp-name, $opacity:0.1) {
  $color: map-get($color-breakpoints, $bp-name);
  @if ($color != null ) {
    background-color: rgba($color, $opacity);
  } @else {
    @warn("!!! Unknow breakpoint #{$bp-name} passed to @mixin bg-breakpoint !!!");
  }
}


// Debug with background
@mixin debug-grid-container($col) {
  @if $col == 1 {
    @include bg-breakpoint(xs, 0.2);
  } @else if $col == 2 {
    @include bg-breakpoint(sm, 0.2);
  } @else if $col == 3 {
    @include bg-breakpoint(md, 0.2);
  } @else if $col == 4 {
    @include bg-breakpoint(lg, 0.2);
  }
}




//  Bootstrap functions (used for grid-breakpoints map validation)
// -----------------------------------------------
//
// Utility mixins and functions for evaluating source code across our variables, maps, and mixins.

// Ascending
// Used to evaluate Sass maps like our grid breakpoints.
@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;
  @each $key, $num in $map {
    // @debug "|| #{$num} / #{$key}";
    @if $prev-num == null {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}

// Starts at zero
// Another grid mixin that ensures the min-width of the lowest breakpoint starts at 0.
@mixin _assert-starts-at-zero($map) {
  $values: map-values($map);
  $first-value: nth($values, 1);
  @if $first-value != 0 {
    @warn "First breakpoint in `$grid-breakpoints` must start at 0, but starts at #{$first-value}.";
  }
}


//- Mixin: Fluid Type
///
/// Magic calc + vh to allow text to be fluid between minimum
/// and maximum breakpoints.
///
/// @group typography
/// @param {variable} $min-font-size [12px] - Minimum font size
/// @param {variable} $max-font-size [24px] - Maximum font size
/// @param {variable} $lower-range [420px] - Stop scaling font smaller at this screen resolution
/// @param {variable} $upper-range [900px] - Stop scaling font larger at this screen resolution
/// @example
///   h1 {
///     @include responsive-type(20px, 48px);
///   }
/// @site https://madebymike.com.au/writing/precise-control-responsive-typography/

@mixin fluid-type($min-font-size: 12px, $max-font-size: 21px, $lower-range: 420px, $upper-range: 900px) {

  font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));

  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range){
    font-size: $max-font-size;
  }
}

//  Custom
// -----------------------------------------------


// Set hover behaviour unless if touchscreen (or active, focus)
@mixin can-hover() {
  // @media(hover: hover) and (pointer: fine) {
    @content;
  // } 
}


// center element in absolute (parent must be positioned)
@mixin abs-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


// flex (or inline-flex) container centered 
@mixin flex-center($type:flex, $direction:null, $align:hv) {
  display: $type;

  @if ($direction) { flex-direction: $direction; }    // flex-direction: row by default
  
  @if ($align == hv) { 
    justify-content: center;
    align-items: center;
  } @else if ($align == h) {
    justify-content: center;
  } @else if ($align == v) {
    align-items: center;
  }
}

// full overlay block in absolute position
// TOUPDATE in boilerplate
@mixin coverer($content:null) {
  @if ($content) { content: '' };
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


// pseudo block solid in absolute position
@mixin pseudo-block($width, $height, $bg-color:null) {
  content: '';
  position: absolute;
  width: $width;
  height: $height;
  @if ($bg-color) {
    background-color: $bg-color;
  }
}

// pseudo block solid in absolute position cover parent
@mixin pseudo-block-cover($bg-color:black) {
  content: '';
  @include coverer();
  background-color: $bg-color;
}


// Triangle mixin
//
// @param {Direction} $direction - Orientation du triangle `up`, `right`, `down` ou `left`
// @param {Color} $color [currentcolor] - Couleur du triangle 
// @param {Size} $sizeH [0.5rem] - Largeur du triangle
// @param {Size} $sizeV [$sizeH] - Largeur du triangle

@mixin triangle($direction, $color, $sizeH, $sizeV:$sizeH) {
    display: inline-block;    
    width: 0;
    height: 0;
    
    // classic triangles : ▼ ▲ ▶ ◀
    @if $direction == down {  
      border-top  : $sizeV solid $color;
      border-left : $sizeH/2 solid transparent;
      border-right: $sizeH/2 solid transparent;
    } @else if $direction == up {
      border-bottom: $sizeV solid $color;
      border-left  : $sizeH/2 solid transparent;
      border-right : $sizeH/2 solid transparent;
    } @else if $direction == right {
      border-left  : $sizeH solid $color;
      border-top   : $sizeV/2 solid transparent;
      border-bottom: $sizeV/2 solid transparent;
    } @else if $direction == left {
      border-right : $sizeH solid $color;
      border-top   : $sizeV/2 solid transparent;
      border-bottom: $sizeV/2 solid transparent;
    
    // corner triangles : ◥ ◢ ◤ ◣ 
    } @else if $direction == upright {
      border-top   : $sizeV/2 solid $color;
      border-left  : $sizeH/2 solid transparent;
      border-right : $sizeH/2 solid $color;
      border-bottom: $sizeV/2 solid transparent;
    } @else if $direction == downright {
      border-top   : $sizeV/2 solid transparent;
      border-left  : $sizeH/2 solid transparent; 
      border-right : $sizeH/2 solid $color;
      border-bottom: $sizeV/2 solid $color;
    } @else if $direction == upleft {
      border-top   : $sizeV/2 solid $color;
      border-left  : $sizeH/2 solid $color;
      border-right : $sizeH/2 solid transparent;
      border-bottom: $sizeV/2 solid transparent;
    } @else if $direction == downleft {
      border-top   : $sizeV/2 solid transparent;
      border-left  : $sizeH/2 solid $color;
      border-right : $sizeH/2 solid transparent;
      border-bottom: $sizeV/2 solid $color;
    } 
    
    //
    // @include bd();
}

// TODO mixin à voir
  @mixin word-wrap() {
    word-break:     break-word;
    hyphens:         auto;
  }

  // TODO mixin à voir
  @mixin ellipsis() {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }





// create aspect-ratio pseudo-element pusher in container
// @include aspect-ratio(16, 9);  -> Width and height
// @include aspect-ratio(1,77);  -> Ratio calculated from width / height
// @include aspect-ratio();  -> No arguments defaults to a 1:1 ratio
// 1:1 aspect ratio = 1 / 1 = 1 = padding-top: 100%
// 4:3 aspect ratio = 3 / 4 = 0.75 = padding-top: 75%
// 3:2 aspect ratio = 2 / 3 = 0.66666 = padding-top: 66.67%
// 16:9 aspect ratio = 9 / 16 = 0.5625 = padding-top: 56.25%
@mixin aspect-ratio($width-or-ratio:1, $height:null) {
  position: relative;
  
  &:before {
    content: '';
    display: block;
    $padding: if($height, percentage($height/$width-or-ratio), percentage(1/$width-or-ratio));
    padding-top: $padding;
  }

  // pull-up child in container
  > * {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}


// object-fit and fallback with polyfill
@mixin object-fit($value: cover) {
  object-fit: $value;
  font-family: 'object-fit: cover;';    // object-fit-images polyfill
} 
//     font-family: 'object-fit: cover; object-position: center center;';    // object-fit-images polyfill


// link with thick line under
@mixin link-fatline($color-text: $color-primary) {
  position: relative;
  display: inline-block;
  font-weight: 700;
  color: $color-text;
  transition: color $trans-duration;
  z-index: 1;

  &:after {
    @include pseudo-block(calc(100% + 4px), 100%, $color-primary);
    z-index: -1;
    bottom: 4px;
    left: -2px;
    opacity: 1;  
    transform-origin: bottom center;
    transform: scaleY(0.12);
    transition: opacity $trans-duration, transform $trans-duration;
  }

  &:hover {
    color: white;

    &:after {
      opacity: 1;
      transform: scaleY(1);
    }
  }

  @include breakpoint(small only) {
    &:after {
      bottom: 2px;
    } 
  }  
}






//  Utils
// -----------------------------------------------

// inline-flex list
@mixin list-inline-flex() {
  @include list();
  display: inline-flex;
}

// flex list
@mixin list-flex() {
  @include list();
  display: flex;
}

// horizontal list
@mixin list-inline-block() {
  @include list();

  > li {
    display:inline-block;
  }
}


// list without bullets
@mixin list() {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}





/// Create burger menu button with icon
/// @param {Number} $width — Icon width
/// @param {Number} $height — Icon height
/// @param {Number} $bar-height — Symbol bar height
/// @param {Hex} $color — Symbol bar color
///
/// @returns {Number}
///
/// @example scss - Usage
/// @include icon-burger(width:18px, height:16px, $bar-height:2px);
///
@mixin icon-burger($width, $height, $bar-height, $color:#000000) {
  $icon-centerY: ($height - $bar-height) / 2;

  // icon wrapper
  position: relative;
  width: $width;
  height: $height;

  // bars
  span {
    position: absolute;
    display: block;
    height: $bar-height;
    width: 100%;
    background-color: $color;
    transition: 0.25s easeOutQuart;
  }


  // default state
  // ☰ burger bars
  span:nth-child(1) {
    top: 0;
    transform-origin: left;
    transition-delay: 0.1s;
  }
  span:nth-child(2) {
    top: $icon-centerY;
    transition-delay: 0;
  }
  span:nth-child(3) {
    bottom: 0;
    transform-origin: right;
    transition-delay: 0.1s;
  }
  // X bars
  span:nth-child(4) {
    top: $icon-centerY;
    transform: scale(0.1) rotate(90deg);
    opacity: 0.5;
    transition-delay: 0;
  }
  span:nth-child(5) {
    top: $icon-centerY;
    transform: scale(0.1) rotate(0deg);
    opacity: 0.5;
    transition-delay: 0;
  }

  // close state
  .btn-burger.-is-active & {
    span:nth-child(1) {
      transform: scaleX(0);
      transition-delay: 0s;
    }
    span:nth-child(2) {
      transform: scaleX(0);
      transition-delay: 0s;
    }
    span:nth-child(3) {
      transform: scaleX(0);
      transition-delay: 0s;
    }
    span:nth-child(4) {
      opacity: 1;
      transform: scale(1) rotate(45deg);
      transition-delay: 0.15s;
    }
    span:nth-child(5) {
      opacity: 1;
      transform: scale(1) rotate(-45deg);
      transition-delay: 0.15s;
    }
  }

  // ∆∆∆∆∆ 4 debug ∆∆∆∆∆
  // @include bd(white,0.8);
  // span {
  //   transition: 0.9s ease-out;
  // }
  // span:nth-child(1) {
  //   background-color: cyan;
  //   display: none;
  // }
  // span:nth-child(2) {
  //   background-color: aliceblue;
  //   display: none;
  // }
  // span:nth-child(3) {
  //   background-color: olivedrab;
  //   display: none;
  // }
  // span:nth-child(4) {
  //   background-color: rgb(197, 186, 120);;
  //   display: none;
  // }
  // span:nth-child(5) {
  //   background-color: rgb(240, 178, 235);;
  //   display: none;
  // }




  // // state burger
  // span:nth-child(1) {
  //   top: 0;
  //   transition-delay: 0.1s;
  // }
  // span:nth-child(2) {
  //   top: $icon-centerY;
  //   transition-delay: 0;
  // }
  // span:nth-child(3) {
  //   top: $icon-centerY;
  //   transition-delay: 0;
  // }
  // span:nth-child(4) {
  //   top: $icon-height - $line-height;
  //   transition-delay: 0.1s;
  // }

  // // state close
  // &.is-active {
  //   span:nth-child(1) {
  //     transform: translateY($icon-centerY);
  //     transition-delay: 0s;
  //     opacity: 0;
  //   }
  //   span:nth-child(2) {
  //     transform: rotate(45deg);
  //     transition-delay: 0.1s;
  //   }
  //   span:nth-child(3) {
  //     transform: rotate(-45deg);
  //     transition-delay: 0.1s;
  //   }
  //   span:nth-child(4) {
  //     transform: translateY(-$icon-centerY);
  //     transition-delay: 0s;
  //     opacity: 0;
  //   }
  // }
}


// clearfix for floats
@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}
