

//  Custom styles
// -----------------------------------------------

@import 'vendors/aos/aos';

@import 'abstracts/functions';
@import 'abstracts/mixins';
@import 'abstracts/variables';

@import 'abstracts/utilities/breakpoints';
@import 'abstracts/utilities/display';
@import 'abstracts/helpers';

@import 'base/reboot';
@import 'base/base';
@import 'base/typography';

@import 'layout/header-site';
@import 'layout/footer-site';

@import "/Users/pierre/Sites/pfp-hiver2021/www/src/scss/components/base/_buttons.scss";

@import "/Users/pierre/Sites/pfp-hiver2021/www/src/scss/components/blocks/_block-collecte.scss";
@import "/Users/pierre/Sites/pfp-hiver2021/www/src/scss/components/blocks/_block-confiance.scss";
@import "/Users/pierre/Sites/pfp-hiver2021/www/src/scss/components/blocks/_block-donation.scss";
@import "/Users/pierre/Sites/pfp-hiver2021/www/src/scss/components/blocks/_block-home.scss";
@import "/Users/pierre/Sites/pfp-hiver2021/www/src/scss/components/blocks/_block-tax.scss";
@import "/Users/pierre/Sites/pfp-hiver2021/www/src/scss/components/blocks/_block-testimonial.scss";
@import "/Users/pierre/Sites/pfp-hiver2021/www/src/scss/components/blocks/_block-thanks.scss";
@import "/Users/pierre/Sites/pfp-hiver2021/www/src/scss/components/blocks/_block-theme.scss";


@import 'shame/shame';


// ∆∆∆∆∆ hide yahoo tags
.ywa-10000 {
  position: absolute;
  bottom: 0;
}