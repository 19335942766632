// ==============================================

//        BLOCK HOME

// ==============================================


.block-home {
  width: 100%;
  height: 100vh;
  color: white;
  overflow: hidden;
  //
  // height: 90vh;
}

.block-home__image-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
}

.block-home__image {
  @include coverer();
  @include object-fit();
  object-position: left top;
}

.block-home .logo-link {
  position: absolute;
  top: 1rem;
  left: 24px;
  pointer-events: auto;
  
  svg {
    width: 160px;
    height: 46px;
    filter: drop-shadow(0 0 2px rgba(black, 0.4));
  }
}


.block-home__inner {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
  // @include bd(cyan,1);
}

.block-home__content {
  position: relative;
  top: -50px;
}

.block-home__title {
  position: relative;
  
  span {
    display: block;
    font-size: 28px;
    font-weight: 500;
    max-width: 400px;
    margin-bottom: 10px;
  }

  strong {
    display: inline-block;
    position: relative;
    padding-left: 60px;
    font-size: 37px;
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;

    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      width: 40px;
      height: 28px;
      background-image: url('../svg/corner-line.svg');
      background-size: contain;
      background-repeat: no-repeat;
      // @include bd(red,1);
    }
  }
  // @include bd(cyan,1);
}

.logo-urgence {
  display: block;
  width: 270px;
  height: 32px;
  margin-bottom: 1rem;
}

.block-home__btn-wrapper {
  position: absolute;
  left: 0;
  bottom: 80px;
  width: 100%;
  text-align: center;
}



//  MQ Breakpoints
// -----------------------------------------------

.block-home {
  @include media-breakpoint-down(md) {
    .block-home__image-wrapper:after {
      @include coverer('');
      background-image: linear-gradient(to top, rgba(black, 1) 10%, rgba(black, 0) 60%);
    }

    .block-home__inner {
      justify-content: center;
      align-items: flex-end;
      height: 100%;
      padding: 0 0 32px;
    }

    .block-home__content {
      top: unset;
      padding: 0 var(--container-padding);
    }
  }

  @include media-breakpoint-down(sm) {
    height: 90vh;

    .logo-link {
      pointer-events: auto;
      top: 10px;
      left: 16px;
      
      svg {
        width: 125px;
        height: 36px;
      }
    }

    .block-home__title {
      position: relative;
      
      span {
        font-size: 21px;
        font-weight: 400;
        max-width: 280px;
        margin-bottom: 8px;
      }
    
      strong {
        padding-left: 40px;
        font-size: 28px;
    
        &:before {
          top: 5px;
          left: 0;
          width: 33px;
          height: 18px;
        }
      }
      // @include bd(cyan,1);
    }

    .logo-urgence {
      width: 224px;
      height: 27px;
      margin-bottom: 6px;
    }
  }
  
  @include media-breakpoint-down(sm) {
    .block-home__image {
      object-position: 10% top;
    }
  }
}
