// ==============================================

//        FUNCTIONS

// ==============================================


//  Colors
// -----------------------------------------------

// Color functions for CSS variables
@function cssVarAlpha($color, $opacity){
  $color:   str-replace($color, 'var(');
  $color:   str-replace($color, ')');
  $color-h: var(#{$color+'-h'});
  $color-s: var(#{$color+'-s'});
  $color-l: var(#{$color+'-l'});
  @return hsla($color-h, $color-s, $color-l, $opacity);
}

@function cssVarLightness($color, $lightnessMultiplier){
  $color:   str-replace($color, 'var(');
  $color:   str-replace($color, ')');
  $color-h: var(#{$color+'-h'});
  $color-s: var(#{$color+'-s'});
  $color-l: var(#{$color+'-l'});
  @return hsl($color-h, $color-s, calc(#{$color-l} * #{$lightnessMultiplier}));
}

@function cssVarSaturation($color, $saturationMultiplier){
  $color: str-replace($color, 'var(');
  $color: str-replace($color, ')');
  $color-h: var(#{$color+'-h'});
  $color-s: var(#{$color+'-s'});
  $color-l: var(#{$color+'-l'});
  @return hsl($color-h, calc(#{$color-s} * #{$saturationMultiplier}), $color-l);
}

// replace substring with another string
// credits: https://css-tricks.com/snippets/sass/str-replace-function/
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}


// A map of z layers
// All z-index values should be set there

$z-layers: (
  'modal'         : 110,
  'overlay'       : 100,
  'megamenu'      : 60,
  'header'        : 50,
  'dropdown'      : 20,
  'default'       : 1,
  'below'         : -1,
  'bottomless-pit': -10000
);

// Set z-index
@function z($layer) {
  @if not map-has-key($z-layers, $layer) {
    @warn "No z-index found in $z-layers map for `#{$layer}`. Property omitted.";
  }

  @return map-get($z-layers, $layer);
}


