// ==============================================

//        TYPOGRAPHY

// ==============================================


//  Body / heading
// -----------------------------------------------

body {
  font-family: $font-body; 
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $color-body-text;
  background-color: $color-body-background;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  font-family: $font-title; 
  // line-height: 1.25;
}

h1,
.h1 {
  font-size: 45px;

  @include media-breakpoint-down(sm) {
    font-size: 35px;
  }
  @include media-breakpoint-down(xs) {
    font-size: 30px;
  }
}

h2,
.h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}


//  Other
// -----------------------------------------------

a {
  position: relative;
  text-decoration: none;
  color: $color-link;
}
