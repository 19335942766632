// ==============================================

//        SHAME

// ==============================================



.rwd-status {
  @include list-inline-block();
  position: fixed; 
  top:0; 
  left:0; 
  z-index: 999;
  margin-top: 0;
  font-size: 14px;
  color: black;

  li { 
    padding: 2px;
    &:nth-child(1) {    @include bg-breakpoint(xs, 0.6);   } 
    &:nth-child(2) {    @include bg-breakpoint(sm, 0.6);   } 
    &:nth-child(3) {    @include bg-breakpoint(md, 0.6);   } 
    &:nth-child(4) {    @include bg-breakpoint(lg, 0.6);   } 
    &:nth-child(5) {    @include bg-breakpoint(xl, 0.6);   } 
    &:nth-child(6) {    @include bg-breakpoint(xxl, 0.6);  } 
  }
}
