// ==============================================

//        FOOTER SITE

// ==============================================


.footer-site {
  display: flex;
  justify-content: center;
  padding: 42px 2rem;
}

.logo-dec {
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
}


.nav-footer__menu {
  @include list-inline-flex();
}

.nav-footer__link {
  display: inline-block;
  padding: 8px 10px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  color: #4E4E4E;

  &:hover {
    color: $color-primary;
  }
}



//  MQ Breakpoints
// -----------------------------------------------

.footer-site {
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: center;

    .logo-dec {
      position: static;
      margin-bottom: 10px;
      transform: unset;
    }

    .nav-footer__menu {
      flex-direction: column;
      align-items: center;
    }
  }
}
