// ==============================================

//        BLOCK COLLECTE

// ==============================================


.block-collecte {
  margin-bottom: 24px;
  padding: 0 2rem;

  // update background when accordion is open
  &.-is-active .block-collecte__inner:before {
    background-position: -200px 0, 0 0;
  }
}

.block-collecte__inner {
  padding: 40px 0 40px 150px;
  border-radius: $border-radius;
  box-shadow: 0 0 10px rgba(black, 0.15);
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 67px;
    left: 0;
    width: 138px;
    height: 138px;
    background-image: url('../svg/bg-collecte1.svg'), url('../svg/bg-collecte2.svg');
    background-size: contain;
    background-position: 0 0, -200px 0;
    background-repeat: no-repeat;
    
    // anim on desktop only
    @include media-breakpoint-up(md) {
      transform: translateX(-200px);
      transition: transform 0.8s 0s easeOutQuad;
    }
  }

  @include media-breakpoint-up(md) {
    &.aos-animate:before {
      transform: translateX(0);
      transition: transform 0.8s 0.5s;
    }
  }
}

.block-collecte__title {
  margin-bottom: 20px;
}

.block-collecte__intro {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-right: 50px;

  p {
    max-width: 250px;
    // @include bd(cyan,1);
  }
  // @include bd(white,1);
}



//  Accordion
// -----------------------------------------------

.info-collecte {    // item-accordion
  padding-right: 150px;
  // @include bd(white,0.2);

  &.-is-active {
    .info-collecte__content {
      height: var(--height-open);
      opacity: 1;
    }

    .btn-collecte {
      pointer-events: none;   // hack

      span {
        opacity: 0;
      }
      .btn-collecte__picto {
        pointer-events: auto; // hack
        .icon {
          transform: rotate(45deg);
        }
      }
    }
    // @include bd();
  }
}

.btn-collecte {
  @include flex-center($type:inline-flex);
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  outline: transparent !important;

  @include can-hover() {
    &:hover {
      span {
        color: white;
        transform: translateX(8px);
      }
      .btn-collecte__picto {
        transform: scale3D(1.2, 1.2, 1);
      }
    }
  }

  .btn-collecte__picto {
    @include flex-center();
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 100%;
    border: 1px solid $color-primary;
    transition: transform 0.5s easeOutQuart;
    will-change: transform;

    .icon {
      width: 12px;
      height: 12px;
      fill: white;
      margin-top: -1px;
      transition: transform $trans-duration;
    }
  }

  span {
    color: $color-primary;
    transition: color 0.5s easeOutQuart, transform 0.5s easeOutQuart, opacity $trans-color;
    will-change: transform;
  }
}


// content wrapper to open / close (no padding)
.info-collecte__content {
  --height-open: auto;        // height value set to auto when open, will be replaced with real value calculated by JS for transition
  height: 0;                  // height value when close (default state)
  overflow: hidden;
  opacity: 0;
  transition: height 0.5s easeInOutQuart, opacity 0.5s linear 0.15s;
  //
  // @include bd(white);
}

// real content inside .item-accordion__content wrapper
.info-collecte__content-inner {
  // @include bd(blue,1);
  padding: 10px 0;

  p:first-of-type {
    max-width: 300px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  h2 {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 32px;

    &:after {
      @include pseudo-block(100%, 2px, $color-primary);
      left: 0;
      bottom: 0;
    }
  }

  .info-collecte__list {
    @include list();
    max-width: 380px;
    
    li {
      display: flex;
      align-items: center;
      margin-bottom: 34px;
      font-weight: 600;
      
      span {
        display: inline-block;
        width: 40px;
        font-size: 22px;
        font-weight: 700;
        color: $color-primary;
      }
    }
    // @include bd(white);
  }

  ul ~ p {
    max-width: 360px;
  }
}

.info-collecte__btn-wrapper {
  position: relative;
  margin-top: 30px;
  padding-top: 40px;
  text-align: center;

  &:after {
    @include pseudo-block(100%, 2px, $color-primary);
    left: 0;
    top: 0;
  }
}


//  MQ Breakpoints
// -----------------------------------------------

.block-collecte {
  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
    padding-bottom: var(--container-padding);

    .block-collecte__inner {
      padding: 130px 28px 40px 28px;
      box-shadow: unset;

      &:before {
        top: 25px;
        left: 50%;
        width: 86px;
        height: 86px;
        transform: translateX(-50%);
      }
    }

    .block-collecte__intro {
      margin-right: 0;

      p {
        max-width: unset;
      }
    }

    .info-collecte {    // item-accordion
      padding-right: 0;
    }

    .info-collecte__content-inner {
      margin-bottom: 30px;

      p:first-of-type,
      ul ~ p,
      .info-collecte__list {
        max-width: unset;
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    .info-collecte__btn-wrapper {
      margin-top: 0;
    
      &:after {
        display: none;
      }
    }

    .info-collecte.-is-active + .info-collecte__btn-wrapper:after {
      display: block;
    }
  }
}
