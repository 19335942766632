// ==============================================

//        GLOBAL LAYOUT

// ==============================================



// ==============================================

//        CONTAINER

// ==============================================

.container {
  position: relative;
  width: 100%;
  max-width: $container-max-width;
  margin-left: auto;
  margin-right: auto;

  &.-fluid {
    max-width: 100%;
  }

  &.-large {
    max-width: $container-large-width;
  }

  &.-medium {
    max-width: $container-medium-width;
  }

  &.-small {
    max-width: $container-small-width;
  }

  &.-h-padding {
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
  }

  &.-rounded {
    border-radius: $border-radius;
    box-shadow: 0 0 10px rgba(black, 0.15);

    @include media-breakpoint-down(sm) {
      border-radius: unset;
      box-shadow: unset;
    }
  }

  &.-bg-white-mobile {
    @include media-breakpoint-down(sm) {
      padding-left: var(--container-padding);
      padding-right: var(--container-padding);
      background-color: white;
    }
  }
}


// default size for SVG icon
.icon {
  width: 1em;
  height: 1em;
  vertical-align: middle;
}

