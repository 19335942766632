// ==============================================

//        HEADER SITE

// ==============================================


.header-site {
  position: fixed;
  top: 0;
  left: 0;
  z-index: z(header);
  width: 100%;
  pointer-events: none;
}

.header-site__inner {
  @include flex-center($align:v);
  justify-content: space-between;
  height: 100%;
  // padding: 24px 2rem;
  padding: 1rem 24px;
  // @include bd(white,1);
}


// only visible for mobile
.header-site .btn {
  margin-left: auto;
  padding: 12px 24px;
  font-size: 13px;
  pointer-events: auto;
}


//  MQ Breakpoints
// -----------------------------------------------

.header-site {
  @include media-breakpoint-down(sm) {
    .header-site__inner {
      padding: 12px 1rem;
    }
    .btn {
      font-size: 12px;
      padding: 12px 26px;
    }
  }
}
