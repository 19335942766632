// ==============================================

//        BLOCK TESTIMONIAL

// ==============================================


.block-testimonial {
  margin-bottom: 90px;
}


.block-testimonial__inner {
  display: grid;
  grid-template-areas: 
    "content1 image"
    "content2 image";
  grid-template-columns: 1fr 1fr;
  padding: 60px var(--container-padding);
  // @include bd(blue,1);
}


//  Grid-area content1
// -----------------------------------------------

.block-testimonial__content1 {
  grid-area: content1;
  padding-right: 76px;
  // @include bd();
}

.block-testimonial__suptitle {
  max-width: 205px;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 600;
}

.block-testimonial__title {
  margin-bottom: 2rem;
  color: $color-primary;
}

.block-testimonial__text1 {
  font-weight: 500;
  font-style: italic;
  margin-bottom: 1rem;
  color: $color-cherry;

  .icon {
    float: left;
    margin-right: 10px;
    margin-bottom: 4px;
    font-size: 40px;
    fill: $color-primary;
  }
}

//  Grid-area content2
// -----------------------------------------------

.block-testimonial__content2 {
  grid-area: content2;
  padding-right: 76px;
  // @include bd();
}


//  Grid-area image
// -----------------------------------------------

.block-testimonial__image-wrapper {
  grid-area: image;
  position: relative;
  border-radius: 187px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);      // bugfix on safari (otherwise border-radius is discarded)

  // -- ALTERNATIVE
//   -webkit-backface-visibility: hidden;
//  -moz-backface-visibility: hidden;
//  -webkit-transform: translate3d(0, 0, 0);
//  -moz-transform: translate3d(0, 0, 0);
  // @include bd();
}

.block-testimonial__image {
  @include coverer();
  @include object-fit();
}



//  MQ Breakpoints
// -----------------------------------------------

.block-testimonial {
  @include media-breakpoint-down(sm) {
    margin-bottom: 0;

    .block-testimonial__inner {
      grid-template-areas: 
        "content1"
        "image"
        "content2";
      grid-template-columns: 1fr;  
      padding: 40px var(--container-padding);
    }

    .block-testimonial__content1,
    .block-testimonial__content2 {
      padding-right: unset;
    }

    .block-testimonial__image-wrapper {
      @include aspect-ratio(320, 170);
      border-radius: 187px;
      margin: 1rem 0 28px;
    }
  }
}
