// ==============================================

//        VARIABLES

// ==============================================


//  Colors
// -----------------------------------------------

// Project colors
$color-body-background: #ffffff;
$color-primary        : #DA324B;
$color-cherry         : #571936;
$color-green          : #2CAC81;
$color-light-grey     : #EDEDED;
$color-grey           : #707070;

// Alias context colors
$color-body-text: black;
$color-link     : $color-primary;



// Map for creating CSS variables from Sass variables (formats hex, hsl, h, s, l)
$colors: (
  color-body-background: $color-body-background,
  color-white          : white,
  color-primary        : $color-primary,
  color-cherry         : $color-cherry,
  color-green          : $color-green,
  color-light-grey     : $color-light-grey,
  color-grey           : $color-grey,
);


//  Fonts
// -----------------------------------------------
$font-body       : 'Montserrat', sans-serif;
$font-title      : 'Montserrat', sans-serif;
$font-size-base  : 16px;
$line-height-base: 1.375;


//  Size / Units
// -----------------------------------------------

// Container's maximum width
$container-max-width   : 1880px;
$container-large-width : 1200px;
$container-medium-width: calc(778px + 4rem);
$container-small-width : 778px;

$border-radius : 20px;
$trans-duration: 0.25s;
$trans-color: 0.2s linear;



//  Grid breakpoints
// -----------------------------------------------

// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs : 0,
  sm : 576px,
  md : 768px,
  lg : 940px,
  xl : 1200px,
  xxl: 1600px
) !default;

// check if $grid-breakpoints is a valid map
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);





//  CSS variables in :root
// -----------------------------------------------

:root {
  // grid breakpoints
  @each $bp, $value in $grid-breakpoints {
    --breakpoint-#{$bp}: #{$value};
  }

   // create CSS variables for colors from Sass variables in different notation
   @each $color-name, $value in $colors {
    @include defineCssVarsColors($color-name, $value);
  }

  --header-height : 76px;
  --container-padding: 2rem;
  --spacer: 1rem;

  @media screen and (max-width: 768px) {
    --container-padding: 26px;
    --header-height: 60px;
  }
}

