// ==============================================

//        BLOCK DONATION

// ==============================================


.block-donation {
  margin-bottom: 24px;
  padding: 0 2rem;
}


.block-donation__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 2rem;
  text-align: center;
}

.block-donation__title {
  max-width: 390px;
  margin-bottom: 26px;
  color: $color-cherry;
}


//  Selector
// -----------------------------------------------

.selector-donation {
  position: relative;
  width: 100%;
  max-width: 460px;
  margin-bottom: 26px;
  border: 1px solid rgba($color-grey, 0.3);
  background-color: rgba($color-light-grey, 0.3);
  border-radius: 43px;
}

.selector-donation__menu {
  @include list-flex();
}

.selector-donation__item {
  flex: 1;
}

.selector-donation__link {
  display: inline-block;
  width: 100%;
  padding: 13px 0;
  font-size: 33px;
  font-weight: 700;
  text-align: center;
  color: rgba($color-grey, 0.6);
  transition: color $trans-color, background-color $trans-color;
  // background-color: rgba(white, 0.3);
  // border-radius: 43px;
  
  // @include can-hover() {
  //   &:hover {
  //     color: white;
  //     // background-color: $color-primary;
  //   }
  // }

  // render button active whith JS when hover
  &.-is-active {
    color: white;
    // background-color: $color-primary;
  }

  span {
    display: inline-block;
    transform: translateX(4px);
  }

  sup {
    display: inline-block;
    font-size: 22px;
    transform: translateY(2px);
  }
}

.selector-donation__indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 43px;
  background-color: $color-primary;
  transition: all 0.45s easeOutQuad;
}


.icon-heart {
  font-size: 32px;
  fill: $color-cherry;
  margin-bottom: 4px;
}



//  Tabs
// -----------------------------------------------

.tabs-donation {
  position: relative;
  // width: 100%;
  
  min-height: 120px;
  // @include bd(red,1);
  // text-align: center;
}

.tab-donation {
  display: none;
  width: 100%;
  max-width: 490px;
  // @include bd();

  &:nth-child(2) {
    max-width: 570px;
  }

  &.-is-active {
    display: block;

    p {
      opacity: 1;
    }
  }

  p {
    margin-bottom: 1rem;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.5;
    opacity: 0;
    transition: opacity 0.3s;

    strong {
      color: $color-cherry;
    }
  }
}




//  MQ Breakpoints
// -----------------------------------------------

.block-donation {
  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
    padding: 0;

    .block-donation__inner {
      padding: 40px 2rem;
      box-shadow: 0 0 10px rgba(black, 0.15);
    }

    .selector-donation {
      max-width: 400px;
      margin-bottom: 20px;
    }

    .selector-donation__link {
      padding: 10px 0;
      font-size: 26px;
    
      span {
        display: inline-block;
        transform: translateX(4px);
      }
    
      sup {
        font-size: 18px;
      }
    }
  }
}
