// ==============================================

//        BLOCK CONFIANCE

// ==============================================


.block-confiance__inner {
  display: flex;
  padding: 60px var(--container-padding);
  
  > * {
    width: 50%;
  }
}


//  Left
// -----------------------------------------------

.block-confiance__image-wrapper {
  position: relative;
  min-height: 450px;
  margin-right: 40px;
  border-radius: 187px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);      // bugfix on safari (otherwise border-radius is discarded)
}

.block-confiance__image {
  @include coverer();
  @include object-fit();
}


//  Right
// -----------------------------------------------

.block-confiance__content {
  h3 {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 700;
    font-style: italic;

    svg {
      font-size: 33px;
      margin-right: 6px;
    }
  }

  p {
    font-size: 13px;
    max-width: 290px;

    &:first-of-type {
      margin-bottom: 40px;
    }
  }
  // @include bd();
}

.block-confiance__title {
  margin-top: 20px;
  margin-bottom: 2rem;
}


//  MQ Breakpoints
// -----------------------------------------------

.block-confiance {
  @include media-breakpoint-down(sm) {
    .block-confiance__image {
      object-position: 3% 50%;
    }
  }

  @include media-breakpoint-down(xs) {
    .block-confiance__inner {
      padding: 40px var(--container-padding);
    }
    .block-confiance__image-wrapper {
      width: 210px;
      max-height: 450px;
      margin-left: -110px;
      margin-right: 28px;
    }

    .block-confiance__image {
      object-position: 9% 50%;
    }

    .block-confiance__content {
      width: auto;
    }
  }
}
