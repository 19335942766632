// ==============================================

//        BLOCK THANKS

// ==============================================


.block-thanks {
  margin-bottom: 60px;
  padding: 0 2rem;
}

.block-thanks__inner {
  @include flex-center();
  padding: 24px 3rem;
  border-radius: $border-radius;
  box-shadow: 0 0 10px rgba(black, 0.15);
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: $color-cherry;

  p {
    margin-bottom: 0;
  }
}



//  MQ Breakpoints
// -----------------------------------------------

.block-thanks {
  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
    padding-bottom: 40px;

    .block-thanks__inner {
      flex-direction: column;
      box-shadow: unset;
    }

    .stars:first-of-type {
      margin-bottom: 4px;
      width: 55px;
    }
    .stars:last-of-type {
      margin-top: 4px;
      width: 55px;
    }
  }
}
