// ==============================================

//        HELPERS CLASSES

// ==============================================


//  Text classes for text-align, vertical-align, uppercase...
// -----------------------------------------------

.u-text-left {    text-align: left;   }
.u-text-right {   text-align: right;    }
.u-text-center {  text-align: center;   }

.u-align-top {        vertical-align: top;    }
.u-align-bottom {     vertical-align: bottom;   }
.u-align-middle {     vertical-align: middle;   }
.u-align-baseline {   vertical-align: baseline; }

.u-lowercase {      text-transform: unset;    }
.u-uppercase {      text-transform: unset;    }
.u-capitalize {     text-transform: capitalize;   }


.u-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.u-break-word {
  word-wrap: break-word;
}




// TOUPDATE in boilerplate
//  Bg colors
// -----------------------------------------------

.u-bg-white {
  background-color: white;
}
.u-bg-cherry {
  color: white;
  background-color: $color-cherry;
}
.u-bg-light-grey {
  background-color: $color-light-grey;
}

.u-bg-green {
  color: white;
  background-color: $color-green;
}

.u-text-primary {
  color: $color-primary;
}

.u-break-space {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}


//  Lists
// TOCLEAN
// -----------------------------------------------

.no-bullets     { list-style: none; }
.bullets-inside { list-style: inside; }
.list-bare {
  margin: 0;
  padding: 0;
  @extend .no-bullets;
}

// prevent body scroll when responsive nav is open
.u-no-scroll {
  overflow: hidden;
}

.u-bg-cover {
  @include coverer();
  @include object-fit();
}



