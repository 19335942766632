// ==============================================

//        BUTTONS

// ==============================================



//  Button classic (solid orange)
//  - modifiers : -grey, -white, -ghost, -icon,
// -----------------------------------------------

.btn {
  --color-text : white;
  --color-bg : var(--color-primary-rgb);
  @include flex-center($type:inline-flex);
  position: relative;
  padding: 20px 50px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid rgba(var(--color-bg),1);
  border-radius: 32px;
  color: var(--color-text);
  background-color: rgba(var(--color-bg),1);
  transition: color 0.5s easeOutQuad, background-color 0.5s easeOutQuad, transform 0.5s easeOutQuart;
  // backface-visibility: hidden;
  // will-change: transform;

  @include can-hover() {
    &:hover {
      color: rgba(var(--color-bg),1);
      background-color: white;
      transform: scale3D(1.05, 1.05, 1);
    }
  }
  

  // -modifiers
  &.-large {
    padding: 20px 80px;
  }
  
  &.-white {
    --color-text : var(--color-primary);
    --color-bg : var(--color-white-rgb);
    // background-color: white;

    @include can-hover() {
      &:hover {
        background-color: var(--color-text);
      }
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 20px 40px;
  }
}


