// ==============================================

//        BLOCK THEMATIQUE

// ==============================================


.block-theme {
  margin-bottom: 40px;
  padding: 0 2rem;
}

.block-theme__inner {
  padding: 40px 0;
  border-radius: $border-radius;
  box-shadow: 0 0 10px rgba(black, 0.15);
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 67px;
    left: 0;
    width: 136px;
    height: 76px;
    background-image: url('../svg/bg-theme.svg');
    background-size: contain;
    background-repeat: no-repeat;

    // anim on desktop only
    @include media-breakpoint-up(md) {
      transform: translateX(-200px);
      transition: transform 0.8s 0s easeOutQuad;
    }
  }

  @include media-breakpoint-up(md) {
    &.aos-animate:before {
      transform: translateX(0);
      transition: transform 0.8s 0.5s;
    }
  }
}

.block-theme__title {
  margin-left: 150px;
  margin-bottom: 20px;
}

.block-theme__intro {
  margin-left: 150px;
  margin-bottom: 40px;
  max-width: 440px;
  // @include bd(white,1);
}



//  Cards theme
// -----------------------------------------------

.list-card-theme {   
  @include list-flex;
  margin: 0 26px;
  --grid-gutter: 12px; 

  > * {
    width: calc((100% - (var(--grid-gutter) * 2) ) / 3);

    &:not(:last-child) {
      margin-right: var(--grid-gutter);
    }
  }
}

.card-theme {
  @include aspect-ratio(1.51);
  @include flex-center();
  flex-direction: column;
  border: 3px solid rgba($color-primary, 0);
  border-radius: $border-radius;
  text-align: center;
  color: white;
  transition: border-color $trans-duration;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);      // bugfix on safari (otherwise border-radius is discarded)

  &:after {
    @include coverer('');
    background-image: linear-gradient(to top, rgba($color-cherry, 1) 0%, rgba($color-cherry, 0) 70%);
    opacity: 0.7;
  }
  
  @include can-hover() {
    &:hover {
      border-color: $color-primary;

      .card-theme__text {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

.card-theme__image {
  @include coverer();
  @include object-fit();
}

.card-theme__content {
  @include flex-center();
  z-index: 1;
}

.card-theme__title {
  @include flex-center($align: v);
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;

  .icon {
    margin-right: 6px;
    font-size: 25px;
    fill: $color-primary;
  }
}

.card-theme__text {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 58px;
  margin-bottom: 0;
  padding: 0 1rem;
  font-size: 12px;
  font-weight: 500;
  transform: translateY(10px);
  opacity: 0;
  transition: transform $trans-duration, opacity $trans-duration;
  will-change: transform;
}




//  MQ Breakpoints
// -----------------------------------------------

.block-theme {
  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
    padding-bottom: var(--container-padding);

    .block-theme__inner {
      padding: 130px 28px 40px 28px;
      box-shadow: unset;

      &:before {
        top: 40px;
        left: 50%;
        width: 94px;
        height: 53px;
        transform: translateX(-50%);
      }
    }

    .block-theme__title {
      margin-left: 0;
    }

    .block-theme__intro {
      margin-left: 0;
      max-width: unset;
    }

    .list-card-theme { 
      flex-direction: column;
      align-items: center;
      margin: 0 12px;
      --grid-gutter: 1rem; 

      > * {
        width: 100%;
        max-width: 236px;

        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: var(--grid-gutter);
        }
      }
    }

    .card-theme .card-theme__text {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
