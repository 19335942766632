// ==============================================

//        BLOCK TAX

// ==============================================


.block-tax {
  margin-bottom: 60px;
  padding: 0 2rem;
}

.block-tax__inner {
  display: flex;
  padding: 0 2rem;
  border-radius: $border-radius;
  box-shadow: 0 0 10px rgba(black, 0.15);
}

.block-tax__title {
  display: flex;
  align-items: center;
  width: 130px;
  margin-bottom: 0;
  padding: 20px 48px 20px 0;
  font-size: 13px;
  color: $color-cherry;
  border-right: 1px solid rgba($color-grey, 0.2);
}

.block-tax__text {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 20px 0px 20px 26px;
  font-size: 12px;
  text-align: center;
}



//  MQ Breakpoints
// -----------------------------------------------

.block-tax {
  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
    padding-bottom: var(--container-padding);

    .block-tax__inner {
      padding: 0 28px;
      box-shadow: unset;
    }
    
    .block-tax__title {
      width: 110px;
    }

    .block-tax__text {
      padding: 20px 0px 20px 18px;
      font-size: 10px;
      text-align: left;
    }
  }
}
