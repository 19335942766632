body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms;
}

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0;
}

body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
  transition-delay: 50ms;
}

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms;
}

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0;
}

body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
  transition-delay: 100ms;
}

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms;
}

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0;
}

body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
  transition-delay: 150ms;
}

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms;
}

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0;
}

body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
  transition-delay: 200ms;
}

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms;
}

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0;
}

body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
  transition-delay: 250ms;
}

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms;
}

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0;
}

body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
  transition-delay: 300ms;
}

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms;
}

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0;
}

body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
  transition-delay: 350ms;
}

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms;
}

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0;
}

body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
  transition-delay: 400ms;
}

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms;
}

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0;
}

body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
  transition-delay: 450ms;
}

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms;
}

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0;
}

body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
  transition-delay: 500ms;
}

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms;
}

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0;
}

body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
  transition-delay: 550ms;
}

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms;
}

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0;
}

body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
  transition-delay: 600ms;
}

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms;
}

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0;
}

body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
  transition-delay: 650ms;
}

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms;
}

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0;
}

body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
  transition-delay: 700ms;
}

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms;
}

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0;
}

body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
  transition-delay: 750ms;
}

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms;
}

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0;
}

body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
  transition-delay: 800ms;
}

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms;
}

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0;
}

body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
  transition-delay: 850ms;
}

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms;
}

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0;
}

body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
  transition-delay: 900ms;
}

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms;
}

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0;
}

body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
  transition-delay: 950ms;
}

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms;
}

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0;
}

body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
  transition-delay: 1000ms;
}

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms;
}

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0;
}

body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
  transition-delay: 1050ms;
}

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms;
}

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0;
}

body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
  transition-delay: 1100ms;
}

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms;
}

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0;
}

body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
  transition-delay: 1150ms;
}

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms;
}

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0;
}

body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
  transition-delay: 1200ms;
}

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms;
}

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0;
}

body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
  transition-delay: 1250ms;
}

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms;
}

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0;
}

body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
  transition-delay: 1300ms;
}

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms;
}

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0;
}

body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
  transition-delay: 1350ms;
}

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms;
}

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0;
}

body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
  transition-delay: 1400ms;
}

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms;
}

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0;
}

body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
  transition-delay: 1450ms;
}

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms;
}

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0;
}

body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
  transition-delay: 1500ms;
}

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms;
}

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0;
}

body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
  transition-delay: 1550ms;
}

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms;
}

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0;
}

body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
  transition-delay: 1600ms;
}

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms;
}

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0;
}

body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
  transition-delay: 1650ms;
}

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms;
}

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0;
}

body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
  transition-delay: 1700ms;
}

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms;
}

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0;
}

body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
  transition-delay: 1750ms;
}

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms;
}

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0;
}

body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
  transition-delay: 1800ms;
}

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms;
}

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0;
}

body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
  transition-delay: 1850ms;
}

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms;
}

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0;
}

body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
  transition-delay: 1900ms;
}

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms;
}

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0;
}

body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
  transition-delay: 1950ms;
}

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms;
}

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0;
}

body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
  transition-delay: 2000ms;
}

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms;
}

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0;
}

body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
  transition-delay: 2050ms;
}

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms;
}

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0;
}

body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
  transition-delay: 2100ms;
}

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms;
}

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0;
}

body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
  transition-delay: 2150ms;
}

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms;
}

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0;
}

body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
  transition-delay: 2200ms;
}

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms;
}

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0;
}

body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
  transition-delay: 2250ms;
}

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms;
}

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0;
}

body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
  transition-delay: 2300ms;
}

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms;
}

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0;
}

body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
  transition-delay: 2350ms;
}

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms;
}

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0;
}

body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
  transition-delay: 2400ms;
}

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms;
}

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0;
}

body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
  transition-delay: 2450ms;
}

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms;
}

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0;
}

body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
  transition-delay: 2500ms;
}

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms;
}

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0;
}

body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
  transition-delay: 2550ms;
}

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms;
}

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0;
}

body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
  transition-delay: 2600ms;
}

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms;
}

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0;
}

body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
  transition-delay: 2650ms;
}

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms;
}

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0;
}

body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
  transition-delay: 2700ms;
}

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms;
}

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0;
}

body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
  transition-delay: 2750ms;
}

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms;
}

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0;
}

body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
  transition-delay: 2800ms;
}

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms;
}

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0;
}

body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
  transition-delay: 2850ms;
}

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms;
}

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0;
}

body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
  transition-delay: 2900ms;
}

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms;
}

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0;
}

body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
  transition-delay: 2950ms;
}

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms;
}

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0;
}

body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
  transition-delay: 3000ms;
}

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^='fade'][data-aos^='fade'].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

[data-aos='smooth-up'] {
  opacity: 0;
  transition-property: opacity, transform;
  transform: translate3d(0, 50px, 0);
}

[data-aos='smooth-up'].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

[data-aos='smooth-left'] {
  opacity: 0;
  transition-property: opacity, transform;
  transform: translate3d(50px, 0, 0);
}

[data-aos='smooth-left'].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

[data-aos='fade-up'] {
  transform: translate3d(0, 100px, 0);
}

[data-aos='fade-down'] {
  transform: translate3d(0, -100px, 0);
}

[data-aos='fade-right'] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos='fade-left'] {
  transform: translate3d(100px, 0, 0);
}

[data-aos='fade-up-right'] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos='fade-up-left'] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos='fade-down-right'] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos='fade-down-left'] {
  transform: translate3d(100px, -100px, 0);
}

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^='zoom'][data-aos^='zoom'].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
}

[data-aos='zoom-in'] {
  transform: scale(0.6);
}

[data-aos='zoom-in-up'] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos='zoom-in-right'] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos='zoom-in-left'] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos='zoom-out'] {
  transform: scale(1.2);
}

[data-aos='zoom-out-up'] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos='zoom-out-right'] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos='zoom-out-left'] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform;
}

[data-aos^='slide'][data-aos^='slide'].aos-animate {
  transform: translate3d(0, 0, 0);
}

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0);
}

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0);
}

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0);
}

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos='flip-left'].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos='flip-right'].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos='flip-up'].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos='flip-down'].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

:root {
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 940px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1600px;
  --color-body-background: #ffffff;
  --color-body-background-hsl: 0deg, 0%, 100%;
  --color-body-background-h: 0deg;
  --color-body-background-s: 0%;
  --color-body-background-l: 100%;
  --color-body-background-rgb: 255, 255, 255;
  --color-white: white;
  --color-white-hsl: 0deg, 0%, 100%;
  --color-white-h: 0deg;
  --color-white-s: 0%;
  --color-white-l: 100%;
  --color-white-rgb: 255, 255, 255;
  --color-primary: #DA324B;
  --color-primary-hsl: 351.07143deg, 69.42149%, 52.54902%;
  --color-primary-h: 351.07143deg;
  --color-primary-s: 69.42149%;
  --color-primary-l: 52.54902%;
  --color-primary-rgb: 218, 50, 75;
  --color-cherry: #571936;
  --color-cherry-hsl: 331.93548deg, 55.35714%, 21.96078%;
  --color-cherry-h: 331.93548deg;
  --color-cherry-s: 55.35714%;
  --color-cherry-l: 21.96078%;
  --color-cherry-rgb: 87, 25, 54;
  --color-green: #2CAC81;
  --color-green-hsl: 159.84375deg, 59.25926%, 42.35294%;
  --color-green-h: 159.84375deg;
  --color-green-s: 59.25926%;
  --color-green-l: 42.35294%;
  --color-green-rgb: 44, 172, 129;
  --color-light-grey: #EDEDED;
  --color-light-grey-hsl: 0deg, 0%, 92.94118%;
  --color-light-grey-h: 0deg;
  --color-light-grey-s: 0%;
  --color-light-grey-l: 92.94118%;
  --color-light-grey-rgb: 237, 237, 237;
  --color-grey: #707070;
  --color-grey-hsl: 0deg, 0%, 43.92157%;
  --color-grey-h: 0deg;
  --color-grey-s: 0%;
  --color-grey-l: 43.92157%;
  --color-grey-rgb: 112, 112, 112;
  --header-height: 76px;
  --container-padding: 2rem;
  --spacer: 1rem;
}

@media screen and (max-width: 768px) {
  :root {
    --container-padding: 26px;
    --header-height: 60px;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 940px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-text-center {
  text-align: center;
}

.u-align-top {
  vertical-align: top;
}

.u-align-bottom {
  vertical-align: bottom;
}

.u-align-middle {
  vertical-align: middle;
}

.u-align-baseline {
  vertical-align: baseline;
}

.u-lowercase {
  text-transform: unset;
}

.u-uppercase {
  text-transform: unset;
}

.u-capitalize {
  text-transform: capitalize;
}

.u-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.u-break-word {
  word-wrap: break-word;
}

.u-bg-white {
  background-color: white;
}

.u-bg-cherry {
  color: white;
  background-color: #571936;
}

.u-bg-light-grey {
  background-color: #EDEDED;
}

.u-bg-green {
  color: white;
  background-color: #2CAC81;
}

.u-text-primary {
  color: #DA324B;
}

@media (max-width: 575.98px) {
  .u-break-space {
    display: none;
  }
}

.no-bullets, .list-bare {
  list-style: none;
}

.bullets-inside {
  list-style: inside;
}

.list-bare {
  margin: 0;
  padding: 0;
}

.u-no-scroll {
  overflow: hidden;
}

.u-bg-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: #212529;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline: 1px dotted #DA324B;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

button {
  padding: 0;
  border-width: 0;
  outline-color: transparent;
  outline-style: none;
  background-color: unset;
}

::placeholder {
  opacity: 1;
}

.container {
  position: relative;
  width: 100%;
  max-width: 1880px;
  margin-left: auto;
  margin-right: auto;
}

.container.-fluid {
  max-width: 100%;
}

.container.-large {
  max-width: 1200px;
}

.container.-medium {
  max-width: calc(778px + 4rem);
}

.container.-small {
  max-width: 778px;
}

.container.-h-padding {
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
}

.container.-rounded {
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

@media (max-width: 767.98px) {
  .container.-rounded {
    border-radius: unset;
    box-shadow: unset;
  }
}

@media (max-width: 767.98px) {
  .container.-bg-white-mobile {
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
    background-color: white;
  }
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: middle;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 1.375;
  color: black;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  font-family: "Montserrat", sans-serif;
}

h1,
.h1 {
  font-size: 45px;
}

@media (max-width: 767.98px) {
  h1,
  .h1 {
    font-size: 35px;
  }
}

@media (max-width: 575.98px) {
  h1,
  .h1 {
    font-size: 30px;
  }
}

h2,
.h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}

a {
  position: relative;
  text-decoration: none;
  color: #DA324B;
}

.header-site {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  pointer-events: none;
}

.header-site__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 1rem 24px;
}

.header-site .btn {
  margin-left: auto;
  padding: 12px 24px;
  font-size: 13px;
  pointer-events: auto;
}

@media (max-width: 767.98px) {
  .header-site .header-site__inner {
    padding: 12px 1rem;
  }
  .header-site .btn {
    font-size: 12px;
    padding: 12px 26px;
  }
}

.footer-site {
  display: flex;
  justify-content: center;
  padding: 42px 2rem;
}

.logo-dec {
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
}

.nav-footer__menu {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: inline-flex;
}

.nav-footer__link {
  display: inline-block;
  padding: 8px 10px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  color: #4E4E4E;
}

.nav-footer__link:hover {
  color: #DA324B;
}

@media (max-width: 767.98px) {
  .footer-site {
    flex-direction: column;
    align-items: center;
  }
  .footer-site .logo-dec {
    position: static;
    margin-bottom: 10px;
    transform: unset;
  }
  .footer-site .nav-footer__menu {
    flex-direction: column;
    align-items: center;
  }
}

.btn {
  --color-text: white;
  --color-bg: var(--color-primary-rgb);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px 50px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid rgba(var(--color-bg), 1);
  border-radius: 32px;
  color: var(--color-text);
  background-color: rgba(var(--color-bg), 1);
  transition: color 0.5s easeOutQuad, background-color 0.5s easeOutQuad, transform 0.5s easeOutQuart;
}

.btn:hover {
  color: rgba(var(--color-bg), 1);
  background-color: white;
  transform: scale3D(1.05, 1.05, 1);
}

.btn.-large {
  padding: 20px 80px;
}

.btn.-white {
  --color-text: var(--color-primary);
  --color-bg: var(--color-white-rgb);
}

.btn.-white:hover {
  background-color: var(--color-text);
}

@media (max-width: 575.98px) {
  .btn {
    padding: 20px 40px;
  }
}

.block-collecte {
  margin-bottom: 24px;
  padding: 0 2rem;
}

.block-collecte.-is-active .block-collecte__inner:before {
  background-position: -200px 0, 0 0;
}

.block-collecte__inner {
  padding: 40px 0 40px 150px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.block-collecte__inner:before {
  content: '';
  position: absolute;
  top: 67px;
  left: 0;
  width: 138px;
  height: 138px;
  background-image: url("../svg/bg-collecte1.svg"), url("../svg/bg-collecte2.svg");
  background-size: contain;
  background-position: 0 0, -200px 0;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .block-collecte__inner:before {
    transform: translateX(-200px);
    transition: transform 0.8s 0s easeOutQuad;
  }
}

@media (min-width: 768px) {
  .block-collecte__inner.aos-animate:before {
    transform: translateX(0);
    transition: transform 0.8s 0.5s;
  }
}

.block-collecte__title {
  margin-bottom: 20px;
}

.block-collecte__intro {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-right: 50px;
}

.block-collecte__intro p {
  max-width: 250px;
}

.info-collecte {
  padding-right: 150px;
}

.info-collecte.-is-active .info-collecte__content {
  height: var(--height-open);
  opacity: 1;
}

.info-collecte.-is-active .btn-collecte {
  pointer-events: none;
}

.info-collecte.-is-active .btn-collecte span {
  opacity: 0;
}

.info-collecte.-is-active .btn-collecte .btn-collecte__picto {
  pointer-events: auto;
}

.info-collecte.-is-active .btn-collecte .btn-collecte__picto .icon {
  transform: rotate(45deg);
}

.btn-collecte {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  outline: transparent !important;
}

.btn-collecte:hover span {
  color: white;
  transform: translateX(8px);
}

.btn-collecte:hover .btn-collecte__picto {
  transform: scale3D(1.2, 1.2, 1);
}

.btn-collecte .btn-collecte__picto {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 100%;
  border: 1px solid #DA324B;
  transition: transform 0.5s easeOutQuart;
  will-change: transform;
}

.btn-collecte .btn-collecte__picto .icon {
  width: 12px;
  height: 12px;
  fill: white;
  margin-top: -1px;
  transition: transform 0.25s;
}

.btn-collecte span {
  color: #DA324B;
  transition: color 0.5s easeOutQuart, transform 0.5s easeOutQuart, opacity 0.2s linear;
  will-change: transform;
}

.info-collecte__content {
  --height-open: auto;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: height 0.5s easeInOutQuart, opacity 0.5s linear 0.15s;
}

.info-collecte__content-inner {
  padding: 10px 0;
}

.info-collecte__content-inner p:first-of-type {
  max-width: 300px;
  font-weight: 600;
  margin-bottom: 20px;
}

.info-collecte__content-inner h2 {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 32px;
}

.info-collecte__content-inner h2:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #DA324B;
  left: 0;
  bottom: 0;
}

.info-collecte__content-inner .info-collecte__list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  max-width: 380px;
}

.info-collecte__content-inner .info-collecte__list li {
  display: flex;
  align-items: center;
  margin-bottom: 34px;
  font-weight: 600;
}

.info-collecte__content-inner .info-collecte__list li span {
  display: inline-block;
  width: 40px;
  font-size: 22px;
  font-weight: 700;
  color: #DA324B;
}

.info-collecte__content-inner ul ~ p {
  max-width: 360px;
}

.info-collecte__btn-wrapper {
  position: relative;
  margin-top: 30px;
  padding-top: 40px;
  text-align: center;
}

.info-collecte__btn-wrapper:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #DA324B;
  left: 0;
  top: 0;
}

@media (max-width: 767.98px) {
  .block-collecte {
    margin-bottom: 0;
    padding-bottom: var(--container-padding);
  }
  .block-collecte .block-collecte__inner {
    padding: 130px 28px 40px 28px;
    box-shadow: unset;
  }
  .block-collecte .block-collecte__inner:before {
    top: 25px;
    left: 50%;
    width: 86px;
    height: 86px;
    transform: translateX(-50%);
  }
  .block-collecte .block-collecte__intro {
    margin-right: 0;
  }
  .block-collecte .block-collecte__intro p {
    max-width: unset;
  }
  .block-collecte .info-collecte {
    padding-right: 0;
  }
  .block-collecte .info-collecte__content-inner {
    margin-bottom: 30px;
  }
  .block-collecte .info-collecte__content-inner p:first-of-type,
  .block-collecte .info-collecte__content-inner ul ~ p,
  .block-collecte .info-collecte__content-inner .info-collecte__list {
    max-width: unset;
  }
  .block-collecte .info-collecte__content-inner p:last-of-type {
    margin-bottom: 0;
  }
  .block-collecte .info-collecte__btn-wrapper {
    margin-top: 0;
  }
  .block-collecte .info-collecte__btn-wrapper:after {
    display: none;
  }
  .block-collecte .info-collecte.-is-active + .info-collecte__btn-wrapper:after {
    display: block;
  }
}

.block-confiance__inner {
  display: flex;
  padding: 60px var(--container-padding);
}

.block-confiance__inner > * {
  width: 50%;
}

.block-confiance__image-wrapper {
  position: relative;
  min-height: 450px;
  margin-right: 40px;
  border-radius: 187px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.block-confiance__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.block-confiance__content h3 {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
  font-style: italic;
}

.block-confiance__content h3 svg {
  font-size: 33px;
  margin-right: 6px;
}

.block-confiance__content p {
  font-size: 13px;
  max-width: 290px;
}

.block-confiance__content p:first-of-type {
  margin-bottom: 40px;
}

.block-confiance__title {
  margin-top: 20px;
  margin-bottom: 2rem;
}

@media (max-width: 767.98px) {
  .block-confiance .block-confiance__image {
    object-position: 3% 50%;
  }
}

@media (max-width: 575.98px) {
  .block-confiance .block-confiance__inner {
    padding: 40px var(--container-padding);
  }
  .block-confiance .block-confiance__image-wrapper {
    width: 210px;
    max-height: 450px;
    margin-left: -110px;
    margin-right: 28px;
  }
  .block-confiance .block-confiance__image {
    object-position: 9% 50%;
  }
  .block-confiance .block-confiance__content {
    width: auto;
  }
}

.block-donation {
  margin-bottom: 24px;
  padding: 0 2rem;
}

.block-donation__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 2rem;
  text-align: center;
}

.block-donation__title {
  max-width: 390px;
  margin-bottom: 26px;
  color: #571936;
}

.selector-donation {
  position: relative;
  width: 100%;
  max-width: 460px;
  margin-bottom: 26px;
  border: 1px solid rgba(112, 112, 112, 0.3);
  background-color: rgba(237, 237, 237, 0.3);
  border-radius: 43px;
}

.selector-donation__menu {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
}

.selector-donation__item {
  flex: 1;
}

.selector-donation__link {
  display: inline-block;
  width: 100%;
  padding: 13px 0;
  font-size: 33px;
  font-weight: 700;
  text-align: center;
  color: rgba(112, 112, 112, 0.6);
  transition: color 0.2s linear, background-color 0.2s linear;
}

.selector-donation__link.-is-active {
  color: white;
}

.selector-donation__link span {
  display: inline-block;
  transform: translateX(4px);
}

.selector-donation__link sup {
  display: inline-block;
  font-size: 22px;
  transform: translateY(2px);
}

.selector-donation__indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 43px;
  background-color: #DA324B;
  transition: all 0.45s easeOutQuad;
}

.icon-heart {
  font-size: 32px;
  fill: #571936;
  margin-bottom: 4px;
}

.tabs-donation {
  position: relative;
  min-height: 120px;
}

.tab-donation {
  display: none;
  width: 100%;
  max-width: 490px;
}

.tab-donation:nth-child(2) {
  max-width: 570px;
}

.tab-donation.-is-active {
  display: block;
}

.tab-donation.-is-active p {
  opacity: 1;
}

.tab-donation p {
  margin-bottom: 1rem;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.5;
  opacity: 0;
  transition: opacity 0.3s;
}

.tab-donation p strong {
  color: #571936;
}

@media (max-width: 767.98px) {
  .block-donation {
    margin-bottom: 0;
    padding: 0;
  }
  .block-donation .block-donation__inner {
    padding: 40px 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
  .block-donation .selector-donation {
    max-width: 400px;
    margin-bottom: 20px;
  }
  .block-donation .selector-donation__link {
    padding: 10px 0;
    font-size: 26px;
  }
  .block-donation .selector-donation__link span {
    display: inline-block;
    transform: translateX(4px);
  }
  .block-donation .selector-donation__link sup {
    font-size: 18px;
  }
}

.block-home {
  width: 100%;
  height: 100vh;
  color: white;
  overflow: hidden;
}

.block-home__image-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
}

.block-home__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  object-position: left top;
}

.block-home .logo-link {
  position: absolute;
  top: 1rem;
  left: 24px;
  pointer-events: auto;
}

.block-home .logo-link svg {
  width: 160px;
  height: 46px;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4));
}

.block-home__inner {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
}

.block-home__content {
  position: relative;
  top: -50px;
}

.block-home__title {
  position: relative;
}

.block-home__title span {
  display: block;
  font-size: 28px;
  font-weight: 500;
  max-width: 400px;
  margin-bottom: 10px;
}

.block-home__title strong {
  display: inline-block;
  position: relative;
  padding-left: 60px;
  font-size: 37px;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
}

.block-home__title strong:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  width: 40px;
  height: 28px;
  background-image: url("../svg/corner-line.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.logo-urgence {
  display: block;
  width: 270px;
  height: 32px;
  margin-bottom: 1rem;
}

.block-home__btn-wrapper {
  position: absolute;
  left: 0;
  bottom: 80px;
  width: 100%;
  text-align: center;
}

@media (max-width: 939.98px) {
  .block-home .block-home__image-wrapper:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, black 10%, rgba(0, 0, 0, 0) 60%);
  }
  .block-home .block-home__inner {
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    padding: 0 0 32px;
  }
  .block-home .block-home__content {
    top: unset;
    padding: 0 var(--container-padding);
  }
}

@media (max-width: 767.98px) {
  .block-home {
    height: 90vh;
  }
  .block-home .logo-link {
    pointer-events: auto;
    top: 10px;
    left: 16px;
  }
  .block-home .logo-link svg {
    width: 125px;
    height: 36px;
  }
  .block-home .block-home__title {
    position: relative;
  }
  .block-home .block-home__title span {
    font-size: 21px;
    font-weight: 400;
    max-width: 280px;
    margin-bottom: 8px;
  }
  .block-home .block-home__title strong {
    padding-left: 40px;
    font-size: 28px;
  }
  .block-home .block-home__title strong:before {
    top: 5px;
    left: 0;
    width: 33px;
    height: 18px;
  }
  .block-home .logo-urgence {
    width: 224px;
    height: 27px;
    margin-bottom: 6px;
  }
}

@media (max-width: 767.98px) {
  .block-home .block-home__image {
    object-position: 10% top;
  }
}

.block-tax {
  margin-bottom: 60px;
  padding: 0 2rem;
}

.block-tax__inner {
  display: flex;
  padding: 0 2rem;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.block-tax__title {
  display: flex;
  align-items: center;
  width: 130px;
  margin-bottom: 0;
  padding: 20px 48px 20px 0;
  font-size: 13px;
  color: #571936;
  border-right: 1px solid rgba(112, 112, 112, 0.2);
}

.block-tax__text {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 20px 0px 20px 26px;
  font-size: 12px;
  text-align: center;
}

@media (max-width: 767.98px) {
  .block-tax {
    margin-bottom: 0;
    padding-bottom: var(--container-padding);
  }
  .block-tax .block-tax__inner {
    padding: 0 28px;
    box-shadow: unset;
  }
  .block-tax .block-tax__title {
    width: 110px;
  }
  .block-tax .block-tax__text {
    padding: 20px 0px 20px 18px;
    font-size: 10px;
    text-align: left;
  }
}

.block-testimonial {
  margin-bottom: 90px;
}

.block-testimonial__inner {
  display: grid;
  grid-template-areas: "content1 image" "content2 image";
  grid-template-columns: 1fr 1fr;
  padding: 60px var(--container-padding);
}

.block-testimonial__content1 {
  grid-area: content1;
  padding-right: 76px;
}

.block-testimonial__suptitle {
  max-width: 205px;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 600;
}

.block-testimonial__title {
  margin-bottom: 2rem;
  color: #DA324B;
}

.block-testimonial__text1 {
  font-weight: 500;
  font-style: italic;
  margin-bottom: 1rem;
  color: #571936;
}

.block-testimonial__text1 .icon {
  float: left;
  margin-right: 10px;
  margin-bottom: 4px;
  font-size: 40px;
  fill: #DA324B;
}

.block-testimonial__content2 {
  grid-area: content2;
  padding-right: 76px;
}

.block-testimonial__image-wrapper {
  grid-area: image;
  position: relative;
  border-radius: 187px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.block-testimonial__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media (max-width: 767.98px) {
  .block-testimonial {
    margin-bottom: 0;
  }
  .block-testimonial .block-testimonial__inner {
    grid-template-areas: "content1" "image" "content2";
    grid-template-columns: 1fr;
    padding: 40px var(--container-padding);
  }
  .block-testimonial .block-testimonial__content1,
  .block-testimonial .block-testimonial__content2 {
    padding-right: unset;
  }
  .block-testimonial .block-testimonial__image-wrapper {
    position: relative;
    border-radius: 187px;
    margin: 1rem 0 28px;
  }
  .block-testimonial .block-testimonial__image-wrapper:before {
    content: '';
    display: block;
    padding-top: 53.125%;
  }
  .block-testimonial .block-testimonial__image-wrapper > * {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.block-thanks {
  margin-bottom: 60px;
  padding: 0 2rem;
}

.block-thanks__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 3rem;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #571936;
}

.block-thanks__inner p {
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .block-thanks {
    margin-bottom: 0;
    padding-bottom: 40px;
  }
  .block-thanks .block-thanks__inner {
    flex-direction: column;
    box-shadow: unset;
  }
  .block-thanks .stars:first-of-type {
    margin-bottom: 4px;
    width: 55px;
  }
  .block-thanks .stars:last-of-type {
    margin-top: 4px;
    width: 55px;
  }
}

.block-theme {
  margin-bottom: 40px;
  padding: 0 2rem;
}

.block-theme__inner {
  padding: 40px 0;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.block-theme__inner:before {
  content: '';
  position: absolute;
  top: 67px;
  left: 0;
  width: 136px;
  height: 76px;
  background-image: url("../svg/bg-theme.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .block-theme__inner:before {
    transform: translateX(-200px);
    transition: transform 0.8s 0s easeOutQuad;
  }
}

@media (min-width: 768px) {
  .block-theme__inner.aos-animate:before {
    transform: translateX(0);
    transition: transform 0.8s 0.5s;
  }
}

.block-theme__title {
  margin-left: 150px;
  margin-bottom: 20px;
}

.block-theme__intro {
  margin-left: 150px;
  margin-bottom: 40px;
  max-width: 440px;
}

.list-card-theme {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  margin: 0 26px;
  --grid-gutter: 12px;
}

.list-card-theme > * {
  width: calc((100% - (var(--grid-gutter) * 2) ) / 3);
}

.list-card-theme > *:not(:last-child) {
  margin-right: var(--grid-gutter);
}

.card-theme {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 3px solid rgba(218, 50, 75, 0);
  border-radius: 20px;
  text-align: center;
  color: white;
  transition: border-color 0.25s;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.card-theme:before {
  content: '';
  display: block;
  padding-top: 66.22517%;
}

.card-theme > * {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.card-theme:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, #571936 0%, rgba(87, 25, 54, 0) 70%);
  opacity: 0.7;
}

.card-theme:hover {
  border-color: #DA324B;
}

.card-theme:hover .card-theme__text {
  transform: translateY(0);
  opacity: 1;
}

.card-theme__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.card-theme__content {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.card-theme__title {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
}

.card-theme__title .icon {
  margin-right: 6px;
  font-size: 25px;
  fill: #DA324B;
}

.card-theme__text {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 58px;
  margin-bottom: 0;
  padding: 0 1rem;
  font-size: 12px;
  font-weight: 500;
  transform: translateY(10px);
  opacity: 0;
  transition: transform 0.25s, opacity 0.25s;
  will-change: transform;
}

@media (max-width: 767.98px) {
  .block-theme {
    margin-bottom: 0;
    padding-bottom: var(--container-padding);
  }
  .block-theme .block-theme__inner {
    padding: 130px 28px 40px 28px;
    box-shadow: unset;
  }
  .block-theme .block-theme__inner:before {
    top: 40px;
    left: 50%;
    width: 94px;
    height: 53px;
    transform: translateX(-50%);
  }
  .block-theme .block-theme__title {
    margin-left: 0;
  }
  .block-theme .block-theme__intro {
    margin-left: 0;
    max-width: unset;
  }
  .block-theme .list-card-theme {
    flex-direction: column;
    align-items: center;
    margin: 0 12px;
    --grid-gutter: 1rem;
  }
  .block-theme .list-card-theme > * {
    width: 100%;
    max-width: 236px;
  }
  .block-theme .list-card-theme > *:not(:last-child) {
    margin-right: 0;
    margin-bottom: var(--grid-gutter);
  }
  .block-theme .card-theme .card-theme__text {
    transform: translateY(0);
    opacity: 1;
  }
}

.rwd-status {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  margin-top: 0;
  font-size: 14px;
  color: black;
}

.rwd-status > li {
  display: inline-block;
}

.rwd-status li {
  padding: 2px;
}

.rwd-status li:nth-child(1) {
  background-color: rgba(255, 224, 130, 0.6);
}

.rwd-status li:nth-child(2) {
  background-color: rgba(197, 225, 165, 0.6);
}

.rwd-status li:nth-child(3) {
  background-color: rgba(128, 222, 234, 0.6);
}

.rwd-status li:nth-child(4) {
  background-color: rgba(159, 168, 218, 0.6);
}

.rwd-status li:nth-child(5) {
  background-color: rgba(244, 143, 177, 0.6);
}

.rwd-status li:nth-child(6) {
  background-color: rgba(255, 171, 145, 0.6);
}

.ywa-10000 {
  position: absolute;
  bottom: 0;
}
